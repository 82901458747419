export const COLORS = {
  PRIMARY: "#FF7F50",
  SECONDARY: "#5598bd",
  ERROR: "#e54b4b",
  SUCCESS: "#cbe56c",
  WHITE: "#EBEAEF",
  RED: "#FF0000",
  BLACK: "#25241f",
}

export const MEDIA_QUERIES = {
  SMALL: "520px",
  MEDIUM: "980px",
  LARGE: "1200px",
}

export const FONT_FAMILIES = {
  TITLE: "Muli",
  TEXT: "Lora",
}

export const MENU = [
  {
    title: "Dancer",
    url: "/dancer/classes",
    children: [
      {
        title: "Dance Classes",
        url: "/dancer/classes",
      },
      {
        title: "Freestyle",
        url: "/dancer/freestyle",
      },
      {
        title: "Choreographer",
        url: "/dancer/choreographer",
      },
      {
        title: "Events",
        url: "/dancer/events",
      },
    ],
  },
  {
    title: "Personal Coach",
    url: "/coach",
  },
  {
    title: "Blog",
    url: "/blog",
  },
]

export const SOCIALS = [
  {
    name: "Facebook",
    link: "https://www.facebook.com/anke.clinck",
  },
  {
    name: "Youtube",
    link: "https://www.youtube.com/channel/UCsNW0Tics9Nd_jU7bcKXS5Q",
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/ankeclinck/",
  },
]

export const formatDateTime = date => {
  const year = date.substring(0, 4)
  const month = date.substring(5, 7)
  const day = date.substring(8, 10)
  const time = date.substring(11, 16)

  return `${month}/${day}/${year} - ${time}`
}

export const formatDate = date => {
  const year = date.substring(0, 4)
  const month = date.substring(5, 7)
  const day = date.substring(8, 10)

  return `${month}/${day}/${year}`
}
