import React, { useState } from "react"
import styled from "styled-components"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { Link } from "gatsby"
import { GoMail } from "react-icons/go"
import { MEDIA_QUERIES, COLORS } from "../constants"

const Container = styled.div`
  display: flex;
  background-color: ${COLORS.BLACK};
  justify-content: space-between;
  padding: 1rem 2%;
  color: white;
  margin: auto;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    flex-wrap: wrap;
  }
`

const LogoRow = styled.div`
  width: 21%;
  padding: 2%;
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    width: 66.66%;
  }
  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  a {
    color: white;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    display: flex;
  }
`

const Row = styled.div`
  width: 21%;
  padding: 2%;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    width: 33.33%;
  }

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    margin-bottom: 1.5rem;
    width: 50%;
  }
`

const NewsletterRow = styled.div`
  width: 37%;
  padding: 2%;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    width: 66.66%;
  }

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    width: 100%;
  }
`

const Title = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.7rem;
`

const LinkContainer = styled.div`
  margin-bottom: 0.35rem;
`

const PageLink = styled(Link)`
  color: white;
  &:hover {
    color: ${COLORS.SECONDARY};
  }
`

const MailLink = styled.a`
  color: white;
  &:hover {
    color: ${COLORS.SECONDARY};
  }
`
const NewsLetter = styled.div`
  display: flex;
  margin-top: 1rem;
  font-size: 1.4rem;
`

const NewsletterInput = styled.input`
  background-color: black;
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  width: 100%;
  flex: 1;
  font-size: 1.4rem;
  color: white;
`

const NewsletterButton = styled.button`
  color: ${COLORS.BLACK};
  background-color: white;
  border: none;
  outline: none;
  transition: 0.2s ease-in all;
  padding: 0.5rem 1rem;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  &:hover {
    color: white;
    background-color: ${COLORS.BLACK};
  }
`
const Message = styled.p`
  color: ${props =>
    props.status === "error" ? COLORS.ERROR : COLORS.SECONDARY};
`

const Footer = () => {
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const submitEmail = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      addToMailchimp(email).then(data => {
        if (data.result === "error") {
          setMessage({
            msg: "Something went wrong! Please try again.",
            status: "error",
          })
        } else {
          setMessage({ status: data.result, msg: data.msg })
        }
      })
      return
    }
    setMessage({ msg: "This is not a valid emailadres!", status: "error" })
  }
  return (
    <Container>
      <LogoRow>
        <Link to="/">ANKE CLINCK</Link>
        <p>Professional Dancer and Personal Coach</p>
      </LogoRow>
      <Row>
        <Title>My Portfolio</Title>
        <LinkContainer>
          <PageLink to="/dancer/freestyle">Freestyle</PageLink>
        </LinkContainer>
        <LinkContainer>
          <PageLink to="/dancer/choreographer">Choreographer</PageLink>
        </LinkContainer>
        <LinkContainer>
          <PageLink to="/coach">Personal Coach</PageLink>
        </LinkContainer>
      </Row>
      <Row>
        <Title>Quick Links</Title>

        <LinkContainer>
          <PageLink to="/dancer/classes">Dance Classes</PageLink>
        </LinkContainer>
        <LinkContainer>
          <PageLink to="/blog">My Blog</PageLink>
        </LinkContainer>
        <LinkContainer>
          <MailLink href="mailto:Anke.clinck@hotmail.com">Contact Me</MailLink>
        </LinkContainer>
      </Row>
      {/* <NewsletterRow>
        <Title>Subscribe to my newsletter</Title>
        <p>Stay up to date with my latest classes and events!</p>
        <NewsLetter>
          <NewsletterInput
            onChange={({ target: { value } }) => setEmail(value)}
            type="email"
            placeholder="Your email"
          />
          <NewsletterButton type="button" onClick={submitEmail}>
            <GoMail style={{ height: "2rem", width: "2rem" }} />
          </NewsletterButton>
        </NewsLetter>
        <Message status={message.status}>{message.msg}</Message>
      </NewsletterRow> */}
    </Container>
  )
}

export default Footer
