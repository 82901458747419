import styled from "styled-components"
import { motion } from "framer-motion"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { MEDIA_QUERIES, COLORS, FONT_FAMILIES } from "../../constants"

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2rem 5%;
  max-width: 1440px;
  margin: auto;
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    padding: 0 5%;
    padding-bottom: 3rem;
  }
`
export const Flex = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    flex-direction: column;
  }
`

export const FullWidthContainer = styled.div`
  display: flex;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    flex-direction: column;
  }
`

export const Text = styled(motion.p)`
  margin-bottom: 1rem;
  font-size: 0.9rem;

  line-height: 1.5;
  width: auto;

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
  }
`

export const List = styled.ul`
  margin-bottom: 2rem;
`

export const ListItem = styled.li`
  margin-bottom: 0.5rem;
  list-style-type: none;
`

export const Title = styled.h1`
  font-family: ${FONT_FAMILIES.TITLE};
  font-size: 3rem;
  color: white;
  padding: 5px 0;
  margin: 0.5rem auto;
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    font-size: 2.5rem;
  }

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    font-size: 1.6rem;
  }
`

export const Subtitle = styled.h3`
  font-size: 2.7rem;
  color: ${COLORS.WHITE};
  background-color: ${COLORS.GREY};
  padding: 5px 15px;
  margin: 0.5rem auto;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    font-size: 1.9rem;
  }

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    font-size: 1.5rem;
  }
`

export const MainHeading = styled(Text)`
  font-size: 4em;
  color: ${COLORS.BLACK};
  margin: 0;
  padding-bottom: 1.5rem;
  padding-top: 2rem;
  font-family: ${FONT_FAMILIES.TITLE};
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    font-size: 3.2rem;
  }

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    font-size: 2.4rem;
    line-height: 2.4rem;
  }
`

export const Heading = styled(motion.h2)`
  text-transform: uppercase;
  font-family: ${FONT_FAMILIES.TITLE};
  color: ${COLORS.BLACK};
  font-weight: 700;
  padding-bottom: 1.5rem;
  padding-top: 2rem;
  font-size: 2.4rem;
  margin: 0;
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    font-size: 2.1rem;
  }

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    font-size: 1.8rem;
  }
`

export const Subheading = styled.h3`
  font-family: ${FONT_FAMILIES.TITLE};
  font-weight: 500;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
`

export const CloseButton = styled(motion.div)`
  position: absolute;
  display: flex;
  justify-content: center;
  right: 2%;
  top: 2%;
  width: 3rem;
  height: 3rem;
  transform-origin: 50% 50%;
  color: ${COLORS.BLACK};
  font-weight: 700;
  font-size: 2rem;
  cursor: pointer;
`

export const RemoveButton = styled.button`
  border: ${COLORS.ERROR} solid 1px;
  background: white;
  border-radius: 5px;
  color: ${COLORS.ERROR};
  padding: 0.4rem;
  margin-left: auto;
  transition: all ease-in 0.2s;
  cursor: pointer;
  outline: none;
  &:hover {
    background: ${COLORS.ERROR};
    color: white;
  }
`

export const Button = styled(motion.button)`
  background: ${COLORS.PRIMARY};
  border: ${COLORS.PRIMARY} solid 1px;
  border-radius: 5px;
  color: white;
  padding: 0.4rem;
  margin-left: auto;
  transition: all ease-in 0.2s;
  cursor: pointer;
  outline: none;
  &:hover {
    background: white;
    color: ${COLORS.PRIMARY};
  }
`

export const BreakLine = styled.hr`
  border: none;
  display: block;
  height: 2px;
  margin: 1.5rem 0;
  background-color: #f5f5f5;
`

export const CTALink = styled.a`
  padding: 10px 20px;
  color: white;
  background-color: ${COLORS.PRIMARY};
  border: solid 1px ${COLORS.PRIMARY};
  border-radius: 2px;
  font-size: 1rem;
  transition: all ease-in 0.2s;
  cursor: pointer;
  &:hover {
    border: solid 1px ${COLORS.PRIMARY};
    background-color: white;
    color: ${COLORS.PRIMARY};
  }

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    font-size: 0.9rem;
  }
`

export const InternalLink = styled(Link)`
  padding: 0.2rem 0.5rem;
  color: white;
  background-color: ${COLORS.PRIMARY};
  border: ${COLORS.PRIMARY} solid 2px;
  font-size: 1rem;
  transition: all ease-in 0.1s;
  cursor: pointer;
  &:hover {
    background: transparent;
    color: ${COLORS.PRIMARY};
  }

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    font-size: 0.9rem;
  }
`

export const TimeTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 0.8rem;
  position: relative;
  z-index: 5;
  tbody {
    max-height: 400px;
    display: block;
    overflow-y: scroll;
  }

  tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
    &:nth-child(even) {
      background-color: #f2f2f2;
    }
    &:nth-child(odd) {
      background-color: white;
    }
  }

  th {
    padding: 0.5rem 1.5rem;
    text-align: left;
    background-color: ${COLORS.BLACK};
    color: white;
  }

  td {
    padding: 0.5rem;

    p {
      font-size: 0.7rem;
      margin: 0 1rem;
      margin-bottom: 1rem;
      line-height: 1rem;
    }
  }
`

export const VideoContainer = styled.div`
  padding-top: 0;
  margin: auto;
  display: flex;
  width: 40%;
  height: 300px;
  div {
    width: 100%;

    height: 300px;
  }

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    width: 100%;

    div {
      padding: 0.15rem 0;
      height: 200px;
    }
  }

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    div {
      height: 150px;
    }
  }
`

export const Banner = styled(BackgroundImage)`
  position: relative;
  width: 100%;
  height: 400px;
  margin-bottom: 2rem;

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    height: 250px;
  }
`
export const StyledTitle = styled(Title)`
  position: relative;
  margin-right: auto;
  text-transform: uppercase;
  margin-left: 0;
  color: ${COLORS.BLACK};

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    padding: 0.3rem 1rem;
  }
`
