import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Navigation from "./Navigation"
import {
  MENU,
  COLORS,
  SOCIALS,
  MEDIA_QUERIES,
  FONT_FAMILIES,
} from "../constants"

const HeaderWrapper = styled.header`
  display: flex;
  position: relative;
  justify-content: center;
  background: #fff;
  margin-bottom: 1.45rem;
  height: 80px;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  padding: 0 2%;

  img {
    max-width: 200px;
    margin: 0;
    padding: 12px 0 0 0;
  }

  .menu {
    display: flex;
    align-items: center;
  }
`

const Logo = styled.div`
  flex-shrink: 0;
  margin: 5px 0 10px 0; /* just to give some spacing */
  width: 20%;
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  text-align: center;

  a {
    color: ${COLORS.BLACK};
  }
`

const Socials = styled.nav`
  margin: 5px 0 10px 0; /* just to give some spacing */
  color: #212121;
  font-family: ${FONT_FAMILIES.TITLE}, Arial, Helvetica, sans-serif;
  font-size: 0.7rem;
  font-weight: 600;
  letter-spacing: 1px;
  display: flex;
  width: 40%;
  align-items: flex-end;
  justify-content: flex-end;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    display: none;
  }
  ul li {
    display: block;
    margin: 0 10px 0 0;
    float: left;
    height: 30px; /* this should be the same as your #main-nav height */
    position: relative; /* this is needed in order to position sub menus */
    cursor: pointer;

    a {
      padding: 0 0 0 10px;
      transition: all 0.2s ease;
      color: ${COLORS.BLACK};
      &:hover {
        color: ${COLORS.PRIMARY};
      }
    }

    span {
      margin: 0 0 0 5px;
    }
  }
`

const Header = () => {
  return (
    <HeaderWrapper>
      <Navigation menu={MENU} />
      <Logo>
        <Link to="/">ANKE CLINCK</Link>
      </Logo>
      <Socials>
        <ul>
          {SOCIALS.map((social, i) => (
            <li key={i}>
              <a target="_blank" rel="noreferrer" href={social.link}>
                {social.name}
              </a>
            </li>
          ))}
        </ul>
      </Socials>
    </HeaderWrapper>
  )
}

export default Header
