import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { GiHamburgerMenu } from "react-icons/gi"

const HamburgerButton = styled.div`
  position: fixed;
  display: block;
  z-index: 1000;
  right: 30px;
  top: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: all 0.2s ease;

  :hover {
    transform: scale(1.2);
  }

  @media (min-width: 992px) {
    display: none;
  }
`

const Hamburger = ({ handleOverlayMenu }) => (
  <HamburgerButton onClick={handleOverlayMenu} tabIndex="0">
    <GiHamburgerMenu style={{ width: "40px", height: "40px" }} />
  </HamburgerButton>
)

Hamburger.propTypes = {
  handleOverlayMenu: PropTypes.func,
}

export default Hamburger
