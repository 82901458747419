import React, { useState } from "react"
import PropTypes from "prop-types"
import { motion } from "framer-motion"
import styled from "styled-components"
import Footer from "./Footer"
import Header from "./header"
import Hamburger from "./Hamburger"
import OverlayMenu from "./OverlayMenu"
import "./layout.css"
import "../style.css"
import { COLORS, MEDIA_QUERIES } from "../constants"

const Contact = styled(motion.a)`
  position: fixed;
  top: 45%;
  transform-origin: 0 50%;
  left: 2rem;
  display: block;
  z-index: 10;
  color: ${COLORS.PRIMARY};
  border-bottom: solid 2px ${COLORS.PRIMARY};

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    left: 1rem;
    border-bottom: none;
    background-color: white;
    padding: 0.2rem 0.5rem;
  }
`

const Layout = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const handleOverlayMenu = () => setMenuOpen(!menuOpen)

  return (
    <div>
      <Contact
        animate={{ rotate: 90, transition: { type: "spring" } }}
        whileHover={{ x: "10px" }}
        href="mailto:Anke.clinck@hotmail.com"
      >
        Contact me
      </Contact>
      <OverlayMenu
        menuOpen={menuOpen}
        handleOverlayMenu={handleOverlayMenu}
      ></OverlayMenu>

      <Hamburger handleOverlayMenu={handleOverlayMenu} />
      <Header />
      <div style={{ paddingTop: "80px" }}>{children}</div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
